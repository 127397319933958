<template>
    <div class="mainpage">
        <img src="@/assets/nh/company.jpg" alt="">
        <div class="topromote" @click="$router.push('/product')">
          <img src="@/assets/nh/topromote.png" alt="">
        </div>
    </div>
</template>
<script>
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import { getStorage } from '@/lib/util'
const { redirect_uri } = config
export default {
  data () {
    return {
      sharelogo: ''
    }
  },
  created () {
    let logodata = getStorage('sys_info', {})
    this.sharelogo = logodata.sharelogo ? logodata.sharelogo : ''
    this.share()
  },
  methods: {
    share () {
      let shareurl = `${redirect_uri}/companyInfo`
      wechatshare(
        '汇立天下：新时代保险普惠',
        `深圳市南华保险经纪有限公司！`,
        this.sharelogo,
        encodeURI(shareurl),
        redirect_uri
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
.mainpage{
      width 100%
      position relative
      .topromote{
        position: absolute;
        bottom : 1rem;
        z-index: 9;
        text-align center
        img{
          width: 85%;
        }
      }
}
</style>
